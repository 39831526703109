// ** React Imports
import { useState, SyntheticEvent, Fragment, useEffect } from 'react';

// ** MUI Imports
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

// ** Icons Imports
import CogOutline from 'mdi-material-ui/CogOutline';
import LogoutVariant from 'mdi-material-ui/LogoutVariant';

// ** Type Imports
import { Settings } from '../../../../@core/context/settingsContext';
import { useNavigate } from 'react-router-dom';
import { setLoginUser } from 'store/Slice/authSlice';
import { useAppDispatch } from 'store/store';

interface Props {
  settings: Settings;
}

// ** Styled Components
// const BadgeContentSpan = styled('span')(({ theme }) => ({
//   width: 8,
//   height: 8,
//   borderRadius: '50%',
//   backgroundColor: theme.palette.success.main,
//   boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
// }))

const UserDropdown = (props: Props) => {
  const dispatch = useAppDispatch();

  // ** Props
  const { settings } = props;

  // ** States
  const header: any = localStorage.getItem('userData');
  const name: any = JSON.parse(header)?.name || '';
  const profileImage: any = JSON.parse(header)?.profileImage || '';
  const type: any = JSON.parse(header)?.type || '';
  const id: string = JSON.parse(header)?.id || '';
  // const firstName: any = JSON.parse(header)?.first_name || 'Eureka'
  // const lastName: any = JSON.parse(header)?.last_name || ''
  const email: any = JSON.parse(header)?.email || '';


  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const navigate = useNavigate();
  // const [userName, setUserName] = useState('')

  // ** Hooks
  // const router = useRouter()
  // const { logout } = useAuth()

  // useEffect(() => {
  //   setUserName(firstName + ' ' + lastName)
  // }, [firstName, lastName])

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url?: string) => {
    if (url) {
      navigate(url);
    }
    setAnchorEl(null);
  };

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary',
    },
  };

  const handleLogout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('uuid');
    localStorage.removeItem('header');
    dispatch(setLoginUser({}));
    if (type === 'super_admin') {
      return navigate('/login');
    }

    window.location.href = 'https://webapp.g3ms.co/auth/logout';
  };

  const types: any = {
    super_admin: 'Super Admin',
    teacher: 'Teacher',
    parent: 'Parent',
    school_leader: 'School Leader',
    district_leader: 'District Leader',
    private_tutor: 'Private Tutor',
    tutor_company: 'Tutor Company',
    tutor: 'Tutor',
    other: 'Other',
  };

  return (
    <Fragment>
      {/* <Badge
        overlap="circular"
        sx={{ ml: 2, cursor: 'pointer' }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      > */}
      <Avatar
        alt={name}
        src={profileImage}
        sx={{ ml: 6, width: 40, height: 40 }}
      />
      {/* </Badge> */}
      <Box
        sx={{
          display: 'flex',
          ml: 3,
          cursor: 'pinter',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
        onClick={(e) => handleDropdownOpen(e)}
      >
        <Tooltip title={id} style={{ zIndex: 100 }}>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontWeight: 600 }}>{'Mr.'}</Typography>
            <Typography sx={{ fontWeight: 600 }}>{name || email}</Typography>
          </Box>
        </Tooltip>

        <Box sx={{ display: 'flex', gap: '3px' }}>
          <Typography
            variant="body2"
            sx={{ fontSize: '0.8rem', color: '#000' }}
          >
            {`${types[type]}`}
          </Typography>
          {/* <Typography
            variant="body2"
            sx={{ fontSize: '0.8rem', color: '#000' }}
          >
            {'5th Grade'}
          </Typography> */}
        </Box>
      </Box>
      <IconButton sx={{ ml: 2 }} onClick={(e) => handleDropdownOpen(e)}>
        <KeyboardArrowDownIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            > */}
            <Avatar
              alt={name}
              src={profileImage}
              sx={{ width: '2.5rem', height: '2.5rem' }}
            />
            {/* </Badge> */}

            <Tooltip title={id}>
              <Box
                sx={{
                  display: 'flex',
                  ml: 3,
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>{name}</Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: '0.8rem', color: 'text.disabled' }}
                >
                  {`${types[type]}`}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Divider sx={{ mt: 0, mb: 1 }} />
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/user/view/12')}>
          <Box sx={styles}>
            <AccountOutline sx={{ mr: 2 }} />
            Profile
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/email')}>
          <Box sx={styles}>
            <EmailOutline sx={{ mr: 2 }} />
            Inbox
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/chat')}>
          <Box sx={styles}>
            <MessageOutline sx={{ mr: 2 }} />
            Chat
          </Box>
        </MenuItem> */}
        {/* <Divider /> */}
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/account-settings')}>
          <Box sx={styles}>
            <CogOutline sx={{ mr: 2 }} />
            Settings
          </Box>
        </MenuItem> */}
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/pricing')}>
          <Box sx={styles}>
            <CurrencyUsd sx={{ mr: 2 }} />
            Pricing
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/faq')}>
          <Box sx={styles}>
            <HelpCircleOutline sx={{ mr: 2 }} />
            FAQ
          </Box>
        </MenuItem> */}
        {/* <Divider /> */}

        <MenuItem sx={{ py: 2 }} onClick={handleLogout}>
          <LogoutVariant
            sx={{ mr: 2, fontSize: '1.375rem', color: 'text.secondary' }}
          />
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
